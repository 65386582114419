import React from 'react';
import parseHtml from 'html-react-parser';

import AppContext from "app/AppContext";
import FormImage from '../../framework/FormImage';
import FollowButton from '../components/FollowButton';
import Socials from '../../framework/Socials';
import Settings from '../../app/settings';

export default class StoreWidget extends React.Component {
    formImageRef = React.createRef();

    state = {
        store: this.props.store,
        viewAsOwner: this.props.viewAsOwner,
        stripeAccount: undefined,
        tabPage: 0
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.store !== this.state.store) {
            this.setState({
                store: nextProps.store
            });
        }
    }

    render() {
        const { store, enableEdit } = this.state;

        if (!store)
            return AppContext.r["preloader"];

        return (
            <div className="store widget">
                <div className="avatar-container">
                    <FormImage type="avatar" viewOnly={!enableEdit}
                        rounded height="128px" maxWidth="128px" className="avatar"
                        ref={this.formImageRef}
                        imageUrl={Settings.apiUrl + '/stores/' + store.id + '/image?updated_at=' + store.updated_at}
                        onDelete={this.onDeleteImage}
                        onImageFileChanged={this.onImageFileChanged} />
                </div>

                <h1>{store.name}</h1>

                {this.state.store.description &&
                    <div id="Description" className="mobile-hidden description">
                        {parseHtml(this.state.store.description)}
                    </div>
                }

                <div className="social-components">
                    <FollowButton store={store} authActions={this.props.authActions} />

                    {(store.socials && store.socials.length > 0) &&
                        <Socials small dark socials={store.socials} />}
                </div>
            </div>
        );
    }
}
