import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parseHtml from 'html-react-parser';
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';

import { LayoutContext, ILayoutContext, defaultSettings } from "@uderly/react-uderly-ui";
import { getAds } from '@uderly/react-uderly-ui/api';
import { Ad } from "@uderly/react-uderly-ui/models";

const AdPopup = () => {
	const layoutContext = useContext(LayoutContext) as ILayoutContext;
	const { t } = useTranslation("translation");

	const [ad, setAd] = useState<Ad>();

	const navigate = useNavigate();

	useEffect(() => {
		const init = async () => {
			const adsResponse = await getAds({ typeId: 20, storeId: 1, useUderlyApi: true });
			console.log(adsResponse);

			if (adsResponse && adsResponse.status === 200) {
				const ad = adsResponse.data.data[0] as Ad | null;

				if (ad) {
					setAd(ad);

					setTimeout(() => {
						layoutContext.dialog.show({
							className: "ad-popup",
							title: <h1>{ad.title}</h1>,
							content: <>
								{(ad.media && ad.media[0]) &&
									<img style={{ marginBottom: "1em" }}
										src={`${defaultSettings.apiUrl}/ads/${ad.media[0].model_id}/media/file?name=${ad.media[0].file_name}`} />}

								{ad.description && parseHtml(ad.description)}

								{ad.url &&
									<div className="iq-button-link">
										<Button className="buyticket-button" color="primary" variant="contained"
											onClick={() => { navigate(ad.url!); layoutContext.dialog.hide(); }}>{(ad.cta_title && ad.cta_title.length > 0) ? ad.cta_title : t('yes')}</Button>
									</div>}
							</>
						});
					}, 30000);
				}
			}
		}

		if (!ad)
			init();
	}, [ad, layoutContext.dialog, navigate, t]);

	return null;
}

export default AdPopup;