import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import { arrayMove } from 'react-movable';
import { Field, FieldArray } from 'formik';
import { connect } from 'react-redux';
import Cookie from 'react-cookies';
import Fab from '@mui/material/Fab';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import AdjustIcon from '@mui/icons-material/Adjust';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Tooltip from '@mui/material/Tooltip';
import { t } from "i18next";

import { updateItemRequest } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import ActionButton from 'framework/ActionButton';
import QuickModule from 'framework/QuickModule';
import { withModal } from 'framework/withModal';
import { arrayRemove } from 'react-movable';
import RemoteSelect from '../../framework/RemoteSelect';
import ProSlider from 'framework/ProSlider';
import QuestionMenu from './QuestionMenu';
import MediaStockImage from '../../framework/MediaStockImage';
import PlaylistsSelect from './PlaylistsSelect';
import { setAlert } from '../../redux/layoutSlice';
import Settings from '../../app/settings';
import withUderlyStore from "../../zustand/withUderlyStore";
import { LayoutContext } from "../../uderly/components/LayoutTools";
import InjestQuestions from "./InjestQuestions";
import { hasPermission } from '../utils';
import PopulatePlaylist from './PopulatePlaylist';

export function indexToLetter(index) {
    switch (index) {
        case 0: return 'A';
        case 1: return 'B';
        case 2: return 'C';
        case 3: return 'D';
        case 4: return 'E';
        default: return '';
    }
}

class PlaylistQuestions extends React.Component {
    static contextType = LayoutContext;

    initialValues = {
        package_id: null,
        text: "",
        duration_ms: 15000,
        image_duration_ms: 7000,
        fixed_score: 0,
        can_edit: true,
        answers: [
            { text: "", is_correct: true },
            { text: "", is_correct: false },
            { text: "", is_correct: false },
            { text: "", is_correct: false }
        ],
        media: null
    };

    state = {
        playlist: this.props.playlist,
        item: { ...this.initialValues },
        records: this.props.playlist.questions,
        query: null,
        selectedQuestionIds: []
    };

    moduleRef = React.createRef();
    autoFocusFieldRef = React.createRef();

    apiPath = "/questions";

    constructor(props) {
        super(props);

        const { isPackage, asCollection } = props;

        this.jsonTableQuestionsRef = React.createRef();

        const basicQuestionHead = [
            {
                Title: AppContext.r["type"],
                ClassName: "type-col td-v-center",
                Adapter: function (o) {
                    return <>
                        {(o.media && o.media[0]) &&
                            <img src={Settings.apiUrl + "/questions/" + o.id + "/image/thumb"} alt={o.id} />}

                        {(o.video_url && o.video_url.length > 0) && <i className="fas fa-video"></i>}

                        {(!o.image_legacy && !o.image && !(o.media && o.media[0]) && (!o.video_url || !o.video_url.length === 0))
                            && <i className="fas fa-font"></i>}
                    </>;
                }
            },
            {
                Title: AppContext.r["text"],
                Field: "text",
                ClassName: "text-col td-v-center"
            },
            {
                Title: AppContext.r["category"],
                Field: "category_id",
                ClassName: "category-col td-v-center",
                Adapter: function (o) {
                    const style = {
                        backgroundColor: (o && o.category) ? o.category.color : "transparent"
                    };
                    const category = (o && o.category) ? o.category.category : "";
                    const categoryImageUrl = Settings.apiUrl + "/categories/" + o.category.id + "/image/thumb";

                    // <div className="category-dot" style={style}>&nbsp;</div>
                    return (<div style={style}><img src={categoryImageUrl} alt={category.category} />{category}</div>);
                }
            },
            {
                Title: AppContext.r["exact-one"],
                Adapter: (o) => {
                    let exact_index = "";

                    if (o && o.answers)
                        o.answers.forEach(function (a, i) {
                            if (a && a.is_correct) {
                                exact_index = i;
                            }
                            // console.log('%d: %s', i, value);
                        });

                    const letter = indexToLetter(exact_index);

                    return (
                        <div className="answers">
                            <div className={"letter " + letter}>
                                {letter}
                            </div>
                        </div>);
                },
                ClassName: "exact-col td-v-center"
            }
        ];

        if (props.allowPlayback)
            this.jQuestionsHead = [
                {
                    Adapter: (o, _index) => {
                        return (<>
                            <ActionButton className="play-question" small tooltip={AppContext.r["play-question-tooltip"]}
                                action={async () => await this.playQuestion(o)} icon="fas fa-play" />
                            <AdjustIcon className="live-question" />
                        </>);
                    },
                    ClassName: "play-col td-v-center"
                },
                ...basicQuestionHead
            ];
        else
            this.jQuestionsHead = [
                {
                    ClassName: "select-col",
                    Adapter: (o) => {
                        if (!o || !o.id)
                            return "";

                        const isChecked = this.state.selectedQuestionIds.filter(qid => qid === o.id).length > 0;

                        return <Checkbox name={o.id} checked={isChecked} onChange={this.handleChangeCheckbox} />;
                    }
                },
                ...basicQuestionHead,
                {
                    Adapter: (o, index) => {
                        return <QuestionMenu
                            onView={!asCollection && o.can_edit ? null : () => this.onEditQuestion(o, true)}
                            onEdit={asCollection || !o.can_edit ? null : () => this.onEditQuestion(o, !o.can_edit)}
                            onDelete={(isPackage && !asCollection) ? () => this.deleteQuestion(o, index) : null}
                            onRemoveFromPlaylist={(isPackage || asCollection) ? null : () => this.onRemoveFrom(o, index)}
                            onMoveToPlaylist={(isPackage || asCollection) ? null : () => this.onMoveToPlaylist(o, index)}
                            onCopyToPlaylist={(isPackage) ? null : () => this.onCopyToPlaylist(o)} />
                    },
                    ClassName: "menu-col"
                }
            ];
    }

    async componentDidMount() {
        const { zustand, playlist } = this.props;

        const params = {
            language_id: playlist.language_id,
            store_id: zustand.store ? zustand.store.id : null
        };

        const categoriesResponse = await IqSpaceApi.StoreCategories(params);

        if (categoriesResponse && categoriesResponse.data) {
            const categories = categoriesResponse.data.data;
            this.categoryOptions = categories;
            this.initialValues.category_id = categories ? categories[0].id : null

            this.setState({
                categories: categories,
                maxPlaylistQuestionsReached: playlist && playlist.questions && playlist.questions.length >= Settings.maxPlaylistQuestions
            });
        }

        this.initialValues.package_id = this.props.packageId ? this.props.packageId : null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.enableWatchPlaylist && nextProps.playlist !== this.state.playlist) {
            const { playlist } = nextProps;

            this.setState({
                playlist: nextProps.playlist,
                records: nextProps.playlist.questions,
                maxPlaylistQuestionsReached: playlist && playlist.questions && playlist.questions.length >= Settings.maxPlaylistQuestions
            });
        }
    }

    onQuestionsChanged(questions) {
        const { playlist } = this.state;

        this.setState({
            maxPlaylistQuestionsReached: playlist && questions && questions.length >= Settings.maxPlaylistQuestions
        });

        if (this.props.onQuestionsChanged)
            this.props.onQuestionsChanged(questions);
    }

    handleChangeCheckbox = (event) => {
        const { selectedQuestionIds } = this.state;
        const { checked, name } = event.target;

        const questionId = parseInt(name);

        const ids = (!checked) ? selectedQuestionIds.filter(id => id !== questionId)
            : [...selectedQuestionIds, questionId];

        console.log(ids)

        this.setState({
            selectedQuestionIds: ids
        });
    }

    deleteQuestion = async (o, index) => {
        const onConfirm = async () => {
            this.props.modal.hide();

            this.onRemoveFrom(o, index);
        }

        this.props.modal.confirm(<><RemoveIcon /> {AppContext.r["remove-question"]}</>,
            AppContext.r["confirm-delete-question"], onConfirm);
    }

    clearPlaylist = async () => {
        const { playlist } = this.state;

        const onConfirm = async () => {
            this.props.modal.hide();

            const response = await IqSpaceApi.ClearPlaylist(playlist.id);

            if (response.status === 202) {
                this.setState({
                    records: []
                });

                this.onQuestionsChanged([]);
            }
        }

        this.props.modal.confirm(<><RemoveIcon /> {AppContext.r["clear-playlist"]}</>,
            AppContext.r["clear-playlist-confirm"], onConfirm);
    }

    onRemoveFrom = async (q, index) => {
        const { playlist } = this.state;

        const response = this.props.isPackage ?
            await IqSpaceApi.DeleteQuestion(q.id)
            : await IqSpaceApi.RemoveFromPlaylist(playlist.id, q.id);

        if (response && (response.status === 202 || response.status === 204)) {
            this.props.setAlert({ open: true, message: AppContext.r["removed-from"] + " \"" + playlist.name + "\"", severity: "warning" });

            const questions = arrayRemove(this.state.records, index);
            //this.moduleRef.current.removeIndex(index);

            const newPlaylist = { ...playlist };
            newPlaylist.questions = questions;
            newPlaylist.questions_count--;

            this.setState({
                playlist: newPlaylist,
                records: questions
            });

            if (this.props.onQuestionRemoved)
                this.props.onQuestionRemoved(q);

            this.onQuestionsChanged(questions);
        } else {
            setAlert({ open: true, message: "Error", severity: "error" });
        }

        return response;
    }

    onMoveToPlaylist = (q, index) => {
        const { setAlert } = this.props;

        const onSelect = async (playlist) => {
            this.props.modal.hide();
            this.moduleRef.current.removeIndex(index);

            const response = await IqSpaceApi.AddToPlaylist(playlist.id, q.id, true, this.state.playlist.id);

            if (response.status === 202) {
                setAlert({ open: true, message: AppContext.r["moved-to-playlist"] + " \"" + playlist.name + "\"", severity: "success" });
            } else if (response.status === 406) {
                setAlert({ open: true, message: AppContext.r["already-in-playlist"], severity: "warning" });
            } else {
                setAlert({ open: true, message: "Error", severity: "error" });
            }
        }

        this.props.modal.setView(<><DriveFileMoveIcon /> {AppContext.r["move-to-playlist"]}</>,
            <PlaylistsSelect modal={this.props.modal} onSelect={onSelect} exclude={this.state.playlist} />, true);
    }

    onCopyToPlaylist = (q) => {
        const { setAlert } = this.props;

        const onSelect = async (playlist) => {
            this.props.modal.hide();

            console.log(playlist, q)

            const response = await IqSpaceApi.AddToPlaylist(playlist.id, q.id, false, /* source */);

            if (response.status === 202) {
                setAlert({ open: true, message: AppContext.r["added-to-playlist"] + " \"" + playlist.name + "\"", severity: "success" });
            } else if (response.status === 406) {
                setAlert({ open: true, message: AppContext.r["already-in-playlist"], severity: "warning" });
            } else {
                setAlert({ open: true, message: "Error", severity: "error" });
            }
        }

        this.props.modal.setView(<><DriveFileMoveIcon /> {AppContext.r["copy-to-playlist"]}</>,
            <PlaylistsSelect modal={this.props.modal} onSelect={onSelect} exclude={this.state.playlist} />, true);
    }

    addRangeToPlaylist = async () => {
        const { selectedQuestionIds } = this.state;
        const { setAlert } = this.props;

        const onSelect = async (playlist) => {
            this.props.modal.hide();

            console.log(playlist, selectedQuestionIds)

            const response = await IqSpaceApi.AddRangeToPlaylist(playlist.id, selectedQuestionIds);
            console.log(response)

            if (response.status === 202) {
                setAlert({ open: true, message: response.data.data.length + AppContext.r["questions-added-to-playlist"] + " \"" + playlist.name + "\"", severity: "success" });

                this.setState({
                    selectedQuestionIds: []
                });
            } else if (response.status === 406) {
                setAlert({ open: true, message: AppContext.r["max-playlist-questions"], severity: "warning" });
            } else {
                setAlert({ open: true, message: "Error " + response.data, severity: "error" });
            }
        }

        this.props.modal.setView(<><DriveFileMoveIcon /> {AppContext.r["copy-to-playlist"]}</>,
            <PlaylistsSelect modal={this.props.modal} onSelect={onSelect} exclude={this.state.playlist} />, true);
    }

    removeRangeFromPlaylist = async () => {
        const { selectedQuestionIds, playlist } = this.state;
        const { setAlert } = this.props;

        const onConfirm = async () => {
            this.props.modal.hide();

            const response = await IqSpaceApi.RemoveRangeFromPlaylist(playlist.id, selectedQuestionIds);

            if (response.status === 202) {
                setAlert({ open: true, message: AppContext.r["questions-removed-from-playlist"] + " \"" + playlist.name + "\"", severity: "success" });

                const questions = this.state.records.filter(q => !selectedQuestionIds.includes(q.id));

                const newPlaylist = { ...playlist };
                newPlaylist.questions = questions;
                newPlaylist.questions_count = questions.length;

                this.setState({
                    selectedQuestionIds: [],
                    playlist: newPlaylist,
                    records: questions
                });

                this.onQuestionsChanged(questions);
            } else {
                setAlert({ open: true, message: "Error", severity: "error" });
            }
        }

        this.props.modal.confirm(<><RemoveIcon /> {AppContext.r["remove-from-playlist"]}</>,
            AppContext.r["confirm-remove-questions"], onConfirm);
    }

    playQuestion = async (question) => {
        await IqSpaceApi.Play(this.props.eventId, question.id, this.state.playlist.id);

        if (this.props.onPlayQuestion)
            this.props.onPlayQuestion(question);
    }

    removeFromPlaylistConfirm = (o, index) => {
        this.props.modal.confirm(AppContext.r["remove-question"], AppContext.r["remove-question-confirm"],
            () => this.removeFromPlaylist(o, index));
    }

    onReordered = async (items, oldIndex, newIndex) => {
        const q0 = items[oldIndex]; // Question to move
        const q1 = items[newIndex]; // Question to be next

        if (q0 && q1) {
            const { playlist, records } = this.state;
            const questions = records ? records : this.state.playlist.questions;

            const orderedQuestions = arrayMove(questions, oldIndex, newIndex);
            playlist.questions = orderedQuestions;

            this.setState({
                playlist: playlist,
                records: orderedQuestions
            });

            const response =
                (!this.props.isPackage) ?
                    await IqSpaceApi.ReorderPlaylist(playlist.id, q0.id, q1.id) :
                    await IqSpaceApi.ReorderPackage(playlist.id, q0.id, q1.id);

            if (response && response.status !== 200) {
                console.log(response)
            }

            this.onQuestionsChanged(orderedQuestions);
        }
    }

    onRowClassName(o) {
        return o.selected ? "playing" : "";
    }

    onQuestionImageFileChanged = async (question, imageFile) => {
        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        if (question.id) {
            await updateItemRequest(Settings.apiUrl + "/questions/" + question.id, { ...question, media: m });
        }

        return m;
    }

    onImageFileChanged = async (imageFile) => {
        const { item } = this.state;

        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });
        console.log({ ...item, media: m })
        if (item.id)
            await updateItemRequest(Settings.apiUrl + this.apiPath + "/" + item.id, { ...item, media: m });
    }

    view = ({ handleChange, values, touched, errors, setFieldValue }) => {
        const isDisabled = this.props.asCollection || !values.can_edit;

        return (
            <>
                <Row className="question-form">
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["text"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["text"]} name="text" maxLength="255" autoFocus ref={this.autoFocusFieldRef}
                                    disabled={isDisabled}
                                    value={values.text} onChange={handleChange}
                                    isValid={touched.text && !errors.text} isInvalid={!!errors.text} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <h3>{AppContext.r["answers"]}</h3>

                            <FieldArray name="answers"
                                render={arrayHelpers => (
                                    <div className="answers">
                                        {values.answers && values.answers.length > 0 && (
                                            values.answers.map((v, index) => (
                                                <div key={"answer" + index}>
                                                    <div className={"letter " + indexToLetter(index)}>{indexToLetter(index)}</div>
                                                    <Field type="text"
                                                        name={`answers[${index}].text`}
                                                        disabled={isDisabled}
                                                        maxLength="100" />

                                                    <label className="checkmark-container">
                                                        <input type="checkbox"
                                                            name={`answers[${index}].is_correct`}
                                                            checked={values.answers[index].is_correct}
                                                            disabled={isDisabled}
                                                            onChange={e => { //e.target.checked
                                                                if (values.answers[index].text.length > 0) {
                                                                    for (var x in values.answers)
                                                                        values.answers[x].is_correct = false;
                                                                    values.answers[index].is_correct = true;
                                                                    this.setState({ item: values });

                                                                    setFieldValue(`answers[${index}].is_correct`, true);
                                                                } else {
                                                                    alert(AppContext.r["empty-answer"])
                                                                }
                                                            }} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>))
                                        )}
                                        <div>
                                        </div>

                                        {typeof errors.answers === 'string' ? <div>{errors.answers}</div> : null}
                                    </div>
                                )} />
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["category"]}</Form.Label>

                                <RemoteSelect name="category_id" key={"category-" + values.category_id} value={values.category_id}
                                    onChange={(e, value) => { setFieldValue("category_id", value); }}
                                    disabled={isDisabled} nullOption={false}
                                    fieldToMap="category" options={this.categoryOptions} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["duration"]}</Form.Label>
                                <ProSlider value={values.duration_ms} adapterDividend={1000} minValue="5" maxValue="100" symbol="s"
                                    disabled={isDisabled}
                                    onChange={(value) => setFieldValue("duration_ms", value)} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} className="fixed-score-group">
                                <Form.Label>{AppContext.r["fixed-score"]}</Form.Label><br />
                                <Form.Label as={Col}>{AppContext.r["fixed-score-desc"]}</Form.Label><br />
                                <ProSlider value={values.fixed_score} step={250}
                                    minValue={0} maxValue={10000} symbol=""
                                    disabled={isDisabled}
                                    onChange={(value) => setFieldValue("fixed_score", value)} />

                                {!values.fixed_score &&
                                    <p className="default">{AppContext.r["fixed-score-default"]}</p>}
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["note"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["note"]} name="note"
                                    disabled={isDisabled}
                                    value={values.note} onChange={handleChange} />
                            </Form.Group>
                        </Row>
                    </Col>

                    <Col md="4">
                        <MediaStockImage sx={{ width: 56, height: 56 }}
                            apiPath={this.apiPath}
                            imageUrl={values.id ? (Settings.apiUrl + this.apiPath + "/" + values.id + "/image") : null}
                            item={values} viewOnly={isDisabled}
                            onImageFileChanged={async (imageFile) => {
                                const media = await this.onQuestionImageFileChanged(values, imageFile);
                                console.log(media)

                                setFieldValue("media", media);
                            }} />

                        {values.image &&
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{AppContext.r["image-duration"]}</Form.Label>
                                    <ProSlider value={values.image_duration_ms} adapterDividend={1000} minValue="3" maxValue="30" symbol="s"
                                        disabled={isDisabled}
                                        onChange={(value) => setFieldValue("image_duration_ms", value)} />
                                </Form.Group>
                            </Row>}

                        <Row>
                            <Form.Label as={Col}>{AppContext.r["video-url"]}<br /><small>{AppContext.r["video-support"]}</small></Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["video-url"]} name="video_url"
                                disabled={isDisabled || values.image}
                                value={values.video_url} onChange={handleChange} />

                            {values.image && AppContext.r["video-url-disabled-desc"]}
                        </Row>
                    </Col>
                </Row>

                <br />
            </>
        )
    };

    fetchCachedCategory = () => {
        const { playlist } = this.state;

        if (playlist) {
            const cachedData = Cookie.load("playlist-" + playlist.id);

            if (cachedData)
                this.initialValues.category_id = cachedData.category_id;
        }
    }

    cacheCategory = (q) => {
        const { playlist } = this.state;

        if (playlist && q && q.category) {
            const data = {
                category_id: q.category.id
            };

            Cookie.save("playlist-" + playlist.id, data, { path: '/' });
        }
    }

    onQuestionEdited = (q) => {
        const records = [...this.state.records];

        for (let i in records) {
            if (records[i].id === q.id) {
                records[i] = { ...q }
                break;
            }
        }

        this.setState({
            records: records
        });

        this.cacheCategory(q);

        this.onQuestionsChanged(records);
    }

    onEditQuestion = async (q, viewOnly = false) => {
        const response = await IqSpaceApi.Question(q.id);
        console.log(response, viewOnly)

        if (response && response.status === 200)
            this.moduleRef.current.onEdit(response.data.data, viewOnly);
    }

    onQuestionInserted = (q, newRecords) => {
        console.log([...this.state.records, q], newRecords)

        this.setState({
            records: newRecords
        });

        this.cacheCategory(q);

        if (this.props.onQuestionInserted)
            this.props.onQuestionInserted(q);

        this.onQuestionsChanged(newRecords);
    }

    onInsertQuestion = () => {
        this.fetchCachedCategory();

        this.moduleRef.current.onInsert();
    }

    questionInsertDataAdapter = (o) => {
        if (!this.props.isPackage)
            o["playlist_id"] = this.state.playlist.id;
        else
            o["package_id"] = this.props.packageId;

        return o;
    }

    questionUpdateDataAdapter = (o) => {
        o["media"] = null;
        return o;
    }

    isPackage = () => !isNaN(this.props.packageId);

    render() {
        const { user } = this.props.zustand;
        const { playlist, categories, selectedQuestionIds, maxPlaylistQuestionsReached } = this.state;
        const { allowPlayback, disableSortable, asCollection } = this.props;

        return (playlist && playlist.questions && categories) ? (
            <div className="playlist-questions">
                {(!allowPlayback && !asCollection) &&
                    <>
                        <div className="top-actions">
                            <Tooltip title={t('insert-question')}>
                                <IconButton aria-label="populate" disabled={maxPlaylistQuestionsReached}
                                    onClick={() => this.onInsertQuestion()}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>

                            {!this.isPackage() && <Tooltip title={t('clear-playlist')}>
                                <IconButton aria-label="populate" disabled={playlist.questions.length === 0}
                                    onClick={async () => await this.clearPlaylist()}>
                                    <ClearAllIcon />
                                </IconButton>
                            </Tooltip>}

                            {!this.isPackage() && <PopulatePlaylist
                                playlistId={playlist.id}
                                disabled={maxPlaylistQuestionsReached}
                                onPerformed={(response) => {
                                    if (response && response.status === 202) {
                                        console.log("added questions " + (this.state.records - response.data.questions));

                                        this.setState({
                                            records: response.data.questions
                                        });

                                        this.onQuestionsChanged(response.data.questions);
                                    }
                                }} />}
                        </div>

                        {maxPlaylistQuestionsReached &&
                            <p className="text-center">{AppContext.r["max-playlist-questions"]}</p>}
                    </>}

                {this.isPackage() && hasPermission(user, "content_management") &&
                    <InjestQuestions packageId={playlist.id} onCompleted={(questions) => {
                        this.setState({
                            records: questions
                        });
                    }} />}

                {this.state.records && this.state.records.length === 0 && (
                    <div className='empty-playlist-message'>
                        <p>{t('playlist-empty')}</p>
                        <p>{t('quickly-add-questions')}</p>
                    </div>)}

                {this.state.records && this.state.records.length > 0 &&
                    <QuickModule ref={this.moduleRef} autoFocusFieldRef={this.autoFocusFieldRef}
                        onReordered={this.onReordered} sortable={!disableSortable}
                        initialValues={this.initialValues}
                        className={this.props.className} enablePagination
                        footerBusy={this.state.footerBusy}
                        baseApiUrl={Settings.apiUrl}
                        records={this.state.records}
                        //apiPath={!allowPlayback ? null : '/playlists/'+ playlist.id + '/questions'}
                        onInserted={this.onQuestionInserted}
                        onUpdated={this.onQuestionEdited}
                        insertDataAdapter={this.questionInsertDataAdapter}
                        updateDataAdapter={this.questionUpdateDataAdapter}
                        createApiPath={'/questions'}
                        updateApiPath={'/questions'}
                        deleteApiPath={'/questions'}
                        query={this.state.query}
                        tableHead={this.jQuestionsHead} schema={this.schema} view={this.view}
                        footerDisabled={this.state.footerDisabled}
                        onRowClassName={this.onRowClassName}
                        disableFooter={asCollection}
                        disableEdit disableInsert disableDelete />
                }

                {selectedQuestionIds.length > 0 &&
                    <div className="selection-actions">
                        <Fab variant="extended" className="addrange-to-playlist" color="secondary"
                            onClick={this.addRangeToPlaylist}>
                            <AddIcon sx={{ mr: 1 }} />
                            {AppContext.r["add-to-playlist"]}
                        </Fab>

                        <Fab variant="extended" className="removerange-from-playlist danger-button" color="secondary"
                            onClick={this.removeRangeFromPlaylist}>
                            <RemoveIcon sx={{ mr: 1 }} />
                            {AppContext.r["remove-from-playlist"]}
                        </Fab>
                    </div>}
            </div>
        ) : AppContext.r["preloader"];
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(withUderlyStore(PlaylistQuestions)));