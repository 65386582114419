import React, { Children, isValidElement, cloneElement } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { LayoutTools, Access, LayoutContext } from '@uderly/react-uderly-ui';
import { useUderlyStore } from "@uderly/react-uderly-ui/zustand";
import AppContext from '../AppContext';
import Header, { scrollTo } from "../../uderly/components/Header";
import Footer from "./Footer.js";
import { hideAlert } from '../../redux/layoutSlice';
import { hideFooter, showFooter } from '../../redux/layoutSlice';
import { withModal } from 'framework/withModal';
import AccountMenu from "../components/AccountMenu";
import * as auth from "../modules/Auth/_redux/authRedux";
import Settings from "../settings";
import AdPopup from "./AdPopup";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Layout extends React.Component {
    static contextType = LayoutContext;

    state = {
        layout: null,
        auth: this.props.auth,
        hideNavigationMenu: false,
        location: this.props.location,
        cookiesAccepted: undefined
    };

    componentDidMount() {
        this.updateLayout();

        window.addEventListener("resize", this.onResize);
        window.addEventListener("scroll", this.onScroll);

        this.defineNavigationBarVisibility();
    }

    defineNavigationBarVisibility() {
        let hideNavigationMenu = false;

        const path = window.location.pathname.split('/');

        const user = useUderlyStore.getState().user;

        if (user && user.stores && path && path.length >= 2) {
            const storeDomain = path[1];

            const s = user.stores.filter(s => s.subdomain === storeDomain);

            if (s && s.length > 0)
                hideNavigationMenu = true;
        }

        this.setState({
            hideNavigationMenu: hideNavigationMenu
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth !== this.state.auth) {
            this.defineNavigationBarVisibility();
        }

        if (nextProps.location !== this.state.location) {
            this.defineNavigationBarVisibility();

            this.setState({
                location: this.props.location
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("scroll", this.onScroll);
    }

    updateLayout = (scrollUp = true) => {
        const slides = document.getElementsByClassName("fullscreen");

        for (var i = 0; i < slides.length; i++) {
            const e = slides.item(i);

            e.style.height = window.innerHeight + "px";
        }

        const heightAsWidthItems = document.getElementsByClassName("height-as-width");

        for (i = 0; i < heightAsWidthItems.length; i++) {
            const e = heightAsWidthItems.item(i);

            e.style.height = e.offsetWidth + "px";
        }

        if (scrollUp) {
            scrollTo('#root');
        }
    }

    onResize = () => {
        this.updateLayout(false);
    }

    onScroll = () => {
        const scrollY = Math.min(window.scrollY / window.innerHeight, 1); // Perc

        const slides = document.getElementsByClassName("fade-out-scrolling-25");

        for (var i = 0; i < slides.length; i++) {
            const el = slides.item(i);

            el.style.opacity = 1 - (scrollY / 25 * 100);
        }
    }

    onSignedIn = async (_data) => {
        this.props.modal.hide();
    }

    onMagicLinkRequested = async () => {
        this.props.modal.hide();
    }

    onForgottenPassword = () => {
        // console.log("onForgottenPassword");
    }

    onLogin = (registering = false) => {
        this.props.modal.setView(<><i className="fas fa-user"></i> {AppContext.r["access"]}</>,
            <Access enableMagicLink onSignedIn={this.onSignedIn}
                onForgottenPassword={this.onForgottenPassword}
                onMagicLinkRequested={this.onMagicLinkRequested} />, true);
    }

    render() {
        const { layout } = this.props;
        const { stores } = useUderlyStore.getState();

        const authActions = {
            onLogin: this.onLogin,
            onRegister: () => { this.onLogin(true); },
        };

        const childrenWithProps = Children.map(this.props.children, child => {
            // Checking isValidElement is the safe way and avoids a TS error too.
            if (isValidElement(child)) {
                return cloneElement(child, {
                    authActions: authActions,
                    updateLayout: this.updateLayout,
                    ...this.props.params
                })
            }

            return child;
        });

        const navBarHeight = (window.innerWidth > 600) ? 70 : 0;

        // Alert Snackbar

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            this.props.hideAlert();
        };

        const onCookiesChecked = (p) => {
            const { location } = this.props;

            console.log(p, location);

            if (location && location.pathname === "/" && p && !this.state.cookiesAccepted) {
                this.setState({
                    cookiesAccepted: true
                });
            }
        }

        return (
            <>
                {!this.props.hideHeader &&
                    <Header siteMap={Settings.siteMap} navBarHeight={navBarHeight}
                        disableAuthentication={this.props.disableAuthentication} authActions={authActions}
                        hideLogo={this.props.hideLogo} alwaysShowBackground={this.props.alwaysShowBackground}
                        hideNavigationMenu={this.state.hideNavigationMenu || this.props.hideNavigationMenu}
                        accountMenu={<AccountMenu alignLeft={this.props.accountMenuAlignLeft}
                            authActions={authActions} />} />}

                <div className={"layout " + this.props.className}>
                    <LayoutTools onCookiesAccepted={onCookiesChecked} onCookiesLoaded={onCookiesChecked}>
                        {(this.state.cookiesAccepted && (!stores || stores.length === 0)) && <AdPopup />}

                        {childrenWithProps}
                    </LayoutTools>
                </div>

                {/* TODEL: Snackbar */}
                <Snackbar open={layout.alert.open} autoHideDuration={layout.alert.autoHideDuration} onClose={handleClose}
                    anchorOrigin={layout.alert.anchorOrigin}>
                    <Alert onClose={handleClose} severity={layout.alert.severity} sx={{ width: '100%' }}>
                        {layout.alert.message}
                    </Alert>
                </Snackbar>

                {(!this.props.disableFooter && (!this.props.controlFooter || layout.footer.visible)) &&
                    <Footer navBarHeight={navBarHeight} />}
            </>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    layout: state.layout
});

const mapDispatchToProps = () => ({
    hideAlert,
    hideFooter,
    showFooter,
    ...auth.actions
});

function withParams(Component) {
    return props => <Component {...props} params={useParams()} searchParams={useSearchParams()} location={useLocation()} />;
}

export default connect(mapStateToProps, mapDispatchToProps())(withModal(withParams(Layout)));