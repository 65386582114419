import React, { useEffect, useState } from 'react';
import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import BusinessIcon from '@mui/icons-material/Business';
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Separator from '../components/Separator';
import CheckIcon from '@mui/icons-material/Check';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import AppContext from '../AppContext';

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

export default function Pricing() {
  const { language } = useUderlyStore((state) => {
    return {
      language: state.language
    };
  });

  const [tiers, setTiers] = useState<any | null>(null);

  useEffect(() => {
    const body = document.querySelector('#root');

    body?.scrollIntoView({
      behavior: 'smooth'
    });

    let currency = "£";

    const tiersEn = [
      {
        title: "Standard",
        price: currency + " 0",
        description: [
          <div className="item">
            <CheckIcon />
            <p>Up to 10 players.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Up to 3 events p/m.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>A lot of fun.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Perfect with your friends.</p>
          </div>,
          <div className="item">
            <CloseIcon />
            <p>Default branding.</p>
          </div>,
          <div className="item">
            <CloseIcon />
            <p>May contain ads.</p>
          </div>,
        ],
        buttonText: AppContext.r["start-now-for-free"],
        buttonVariant: "outlined",
        to: "/play"
      },
      {
        title: "Pro",
        subheader: "Limited Time Launch Deal",
        priceCrossedOut: currency + " 59",
        price: currency + " 0 for 3 months",
        priceDesc: "No contract. No ties. No hassle.",
        description: [
          <div className="item">
            <CheckIcon />
            <p>Up to 90 players.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Up to 10 events p/m.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Yet, even more fun.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Your logo always on.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>No ads. Maybe yours?</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Priority email support.</p>
          </div>
        ],
        buttonText: AppContext.r["start-now-for-free"],
        buttonVariant: "contained",
        to: "/play"
      },
      {
        title: "Pro UNLIMITED",
        subheader: "Limited Time Launch Deal",
        priceCrossedOut: currency + " 99",
        price: currency + " 0 for 3 months",
        priceDesc: "No contract. No ties. No hassle.",
        description: [
          <div className="item">
            <CheckIcon />
            <p>Up to 90 players.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p><strong>Unlimited</strong> events.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Yet, even more fun.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Your logo always on.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>No ads. Maybe yours?</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Priority email support.</p>
          </div>
        ],
        buttonText: AppContext.r["start-now-for-free"],
        buttonVariant: "contained",
        to: "/play"
      },
      {
        title: "Business",
        price: "",
        className: "business",
        md: 12,
        description: [
          <div className="item">
            <PhoneIcon />
            <p>Uhm. You must be ambitious.<br />Wanna have a chat?</p>
          </div>,
          <div className="item">
            <BusinessIcon />
            <Link to="/business">Learn More: IQ Space for Business</Link>
          </div>
        ],
        buttonText: AppContext.r["contact-us"],
        buttonVariant: "outlined",
        to: "/business#business-contactus"
      },
    ];

    currency = "€";

    const tiersIt = [
      {
        title: "Standard",
        price: currency + " 0",
        description: [
          <div className="item">
            <CheckIcon />
            <p>Fino a 10 giocatori.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Fino a 3 eventi al mese.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Tanto divertimento.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Perfetta con i tuoi amici.</p>
          </div>,
          <div className="item">
            <CloseIcon />
            <p>Brand di default.</p>
          </div>,
          <div className="item">
            <CloseIcon />
            <p>Potrebbe contenere pubblicità.</p>
          </div>,
        ],
        buttonText: AppContext.r["start-now-for-free"],
        buttonVariant: "outlined",
        to: "/play"
      },
      {
        title: "Pro",
        subheader: "Offerta Promozionale Lancio",
        priceCrossedOut: currency + " 59",
        price: currency + " 0 per 3 mesi",
        priceDesc: "Nessun contratto. Nessun impegno. Nessuna seccatura.",
        description: [
          <div className="item">
            <CheckIcon />
            <p>Fino a 90 giocatori.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Fino a 10 eventi al mese.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Tantissimo divertimento.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Il tuo <strong>logo</strong> sempre in mostra.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Nessuna pubblicità.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Supporto per e-mail prioritario.</p>
          </div>
        ],
        buttonText: AppContext.r["start-now-for-free"],
        buttonVariant: "contained",
        to: "/play"
      },
      {
        title: "Pro ILLIMITATO",
        subheader: "Offerta Promozionale Lancio",
        priceCrossedOut: currency + " 99",
        price: currency + " 0 per 3 mesi",
        priceDesc: "Nessun contratto. Nessun impegno. Nessuna seccatura.",
        description: [
          <div className="item">
            <CheckIcon />
            <p>Fino a 90 giocatori.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Eventi <strong>illimitati</strong>.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Tantissimo divertimento.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Il tuo <strong>logo</strong> sempre in mostra.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Nessuna pubblicità.</p>
          </div>,
          <div className="item">
            <CheckIcon />
            <p>Supporto per e-mail prioritario.</p>
          </div>
        ],
        buttonText: AppContext.r["start-now-for-free"],
        buttonVariant: "contained",
        to: "/play"
      },
      {
        title: "Business",
        price: "",
        className: "business",
        md: 12,
        description: [
          <div className="item">
            <PhoneIcon />
            <p>Uhm. Devi essere un tipo ambizioso.<br />Ne vuoi parlare?</p>
          </div>,
          <div className="item">
            <BusinessIcon />
            <Link to="/business">Info: IQ Space per il Business</Link>
          </div>
        ],
        buttonText: AppContext.r["contact-us"],
        buttonVariant: "outlined",
        to: "/business#business-contactus"
      },
    ];

    setTiers(language === "it" ? tiersIt : tiersEn);
  }, [language]);

  return (
    <Container maxWidth="lg" className="page pricing">
      <h1>{t('pricing')}</h1>
      <Separator />
      <h2>{t('pricing-desc')}</h2>

      <div className="promo-banner">
        <h1>{t('three-months-free')}</h1>

        <img alt="Logo" className="logo" src={AppContext.s['iqspace-logo-light']} />

        <h2>
          <Trans i18nKey="pricing-promo-banner">
            We are giving our <strong>full unlimited product</strong> for <strong>free</strong> to <br />100 entertainers for 3 months.
          </Trans>
        </h2>
        <h3>{t('limited-time-cancel')}</h3>
      </div>

      <Grid container className="plans" spacing={5} alignItems="flex-end">
        {tiers && tiers.map((tier) => (
          <Grid
            item
            className={`plan ${tier.className ?? ""}`}
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={tier.md ?? 4}>
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: "center" }}
                action={tier.title === "Pro" ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[700],
                }}
              />
              <CardContent className="pricing-card-content">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    mb: 2,
                  }}>

                  {tier.price && (
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.priceCrossedOut && <><s>{tier.priceCrossedOut}</s> </>}
                      {tier.price}
                    </Typography>)}

                  {tier.priceDesc &&
                    <small>
                      {tier.priceDesc}
                    </small>}
                </Box>

                <PricingList>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}>
                      {line}
                    </Typography>
                  ))}
                </PricingList>
              </CardContent>
              <CardActions>
                <Button className="cta-button" component={Link} fullWidth variant={tier.buttonVariant as "text" | "outlined" | "contained"} to={tier.to}>
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <div className="ticket-fees" >
        <h2>{AppContext.r["ticket-fees"]}</h2>

        <Grid container spacing={2} alignItems={"center"}>
          <Grid item md={8} lg={7}>
            {AppContext.r["ticket-fees-desc"]}
          </Grid>

          <Grid item md={4} lg={5} className="ticket-image">
            <img src="/assets/images/makeithappen.jpg" alt="Tickets" />
          </Grid>
        </Grid>
      </div>
      <br />
      <p className="text-center"><small>{AppContext.r["prices-exclude-vat"]}</small></p>
    </Container>
  );
}
