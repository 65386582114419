import React from "react";
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

import AppContext from "app/AppContext";
import IqSpaceApi from "api/IqSpace";
import withUderlyStore from "../../zustand/withUderlyStore";

class FollowButton extends React.Component {
    constructor(props) {
        super(props);

        const { store } = this.props;
        const following = (store && store.following) ? store.following : 0;

        this.state = {
            user: this.props.zustand.user,
            following: following
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.user !== this.state.user) {
            this.setState({
                user: nextProps.zustand.user
            });
        }
    }

    handleClick = async () => {
        const { following } = this.state;

        if (!this.state.user) {
            this.props.authActions.onLogin();
        } else {
            let response = null;

            if (!following) {
                response = await IqSpaceApi.Follow(this.props.store.id);
            } else {
                response = await IqSpaceApi.Unfollow(this.props.store.id);
            }

            if (response && response.status === 200)
                this.setState({
                    following: !following ? 1 : 0
                });
        }
    }

    render() {
        const { following } = this.state;

        const className = "following-button light " + this.props.className;

        return following ? (
            <Button variant="outlined" color="secondary" className={className + " unfollow"}
                onClick={this.handleClick}
                startIcon={<HeartBrokenIcon />}>
                {AppContext.r["unfollow"]}
            </Button>
        ) : (
            <Button variant="outlined" color="primary" className={className + " follow"}
                onClick={this.handleClick}
                startIcon={<FavoriteIcon />}>
                {AppContext.r["follow"]}
            </Button>
        )
    }
}

export default withUderlyStore(FollowButton);